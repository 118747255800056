<template src="./template.html"></template>

<script>
export default {
  name: 'Loading',
  data() {
    return {}
  },
  props: ['Loading_open'],
  methods: {
    CloseLoading() {
      this.$emit('CloseLoading')
    }
  }
}
</script>