var default_meta = {
  title: '重視監測品質的專業監測工程公司',
  content:
    '本公司成立於民國七十七年八月，二十餘年來，本著穩健、誠實之經營，使公司日漸成長，目前設有工程、業務、管理及研發各部專職員工，並延聘國內之大地工程專家、教授擔任長期顧問，以協助業主處理有關工程問題及提供完善之建議與服務。',
  image: '/img/index-header/home_cover.jpg',
}

export function GetMetaData(title, content, image) {
  title = title == '' ? default_meta.title : title
  return {
    title: '鼎創工程 | ' + title,
    meta: [
      {
        property: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        content: '鼎創工程 | ' + title,
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        content: '鼎創工程 | ' + title,
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        content: '鼎創工程 | ' + title,
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  }
}
