<template>
  <div id="YCF-Contact">
    <Loading :Loading_open="Loading_open" v-on:CloseLoading="CloseLoading" />
    <div class="row">
      <div class="YCF-Contact-title">
        <h1>聯絡我們</h1>
        <hr />
      </div>
      <div class="YCF-Contact-information">
        <div class="YCF-Contact-information-image">
          <img style="width: 300px" :src="ContactInfo.img" />
        </div>
        <div class="YCF-Contact-information-text">
          <h2>{{ ContactInfo.name }}</h2>
          <address>
            <p>{{ ContactInfo.address }}</p>
            <p>公司電話：{{ ContactInfo.phone }}</p>
            <p>公司傳真：{{ ContactInfo.fax }}</p>
            <p>公司信箱：{{ ContactInfo.email }}</p>
            <!-- <p>統一編號：{{ ContactInfo.id_code }}</p> -->
          </address>
        </div>
      </div>
      <div class="YCF-Contact-information">
        <div class="YCF-Contact-information-image">
          <img style="width: 200px" :src="ContactInfo_1.img" />
        </div>
        <div class="YCF-Contact-information-text">
          <h2>{{ ContactInfo_1.name }}</h2>
          <address>
            <p>{{ ContactInfo_1.address }}</p>
            <p>公司電話：{{ ContactInfo_1.phone }}</p>
            <p>公司傳真：{{ ContactInfo_1.fax }}</p>
            <p>公司信箱：{{ ContactInfo_1.email }}</p>
            <!-- <p>統一編號：{{ ContactInfo.id_code }}</p> -->
          </address>
        </div>
      </div>
      <div class="YCF-Contact-form">
        <div class="contactform">
          <div class="col-md-12 error_message">
            <p>
              輸入項目中有空白或錯誤。請從以下確認內容，再次點擊“送出”按鈕。
            </p>
          </div>

          <div class="contactgroup col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <p class="title">聯繫人姓名</p>
                <p class="musttag">必填</p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="UserData.name"
                  class="form-control"
                  id="Name"
                  placeholder="請輸入姓名"
                />
              </div>
            </div>

            <div style="clear: both"></div>
            <div class="error_text">
              <div class="col-md-3"></div>
              <div class="col-md-4 text-left">
                <p class="error_name">「姓名」必須輸入。</p>
              </div>
            </div>
          </div>

          <div class="contactgroup col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <p class="title">電話</p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="UserData.phone"
                  class="form-control"
                  id="Phone"
                  placeholder="請輸入電話號碼"
                />
              </div>
            </div>
          </div>

          <div class="contactgroup col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <p class="title">電子信箱</p>
                <p class="musttag">必填</p>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="UserData.email"
                  class="form-control"
                  id="Email"
                  placeholder="請輸入電子信箱"
                />
              </div>
            </div>

            <div style="clear: both"></div>
            <div class="error_text">
              <div class="col-md-3"></div>
              <div class="col-md-4 text-left">
                <p class="error_email">「電子信箱」必須輸入。</p>
              </div>
            </div>
          </div>

          <div class="contactgroup col-md-12">
            <div class="form-group">
              <div class="col-md-3">
                <p class="title">問題內容</p>
                <p class="musttag">必填</p>
              </div>
              <div class="col-md-6">
                <textarea
                  class="form-control"
                  v-model="UserData.commit"
                  rows="5"
                  id="comment"
                ></textarea>
              </div>
            </div>

            <div style="clear: both"></div>
            <div class="error_text">
              <div class="col-md-3"></div>
              <div class="col-md-4 text-left">
                <p class="error_content">「問題內容」必須輸入。</p>
              </div>
            </div>
          </div>

          <div class="contactgroup col-md-12 text-center">
            <button class="btn submit-btn" @click="SendMail">送出</button>
          </div>

          <div style="clear: both"></div>
        </div>
      </div>
    </div>

    <section id="YCF-map">
      <iframe
        :src="this.Map.url"
        height="450"
        frameborder="0"
        style="border: 0; width: 100%"
        type="application/pdf"
        allowfullscreen
      ></iframe>
    </section>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js'
import qs from 'qs'
import Loading from '../components/Loading/index'
export default {
  name: 'Contact',
  data() {
    return {
      Loading_open: 'Close',
      ContactInfo: {
        img: '/img/LOGO.svg',
        name: '鼎創工程股份有限公司',
        email: 'dingchong.tw@gmail.com',
        phone: '+886-2-23770651',
        fax: '+886-2-23770652',
        address: '110004台北市信義區富陽街71巷38號',
        // id_code: '54958606'
      },
      ContactInfo_1: {
        img: '/img/YST_LOGO.svg',
        name: '永嵩科技工程股份有限公司',
        email: 'dingchong.tw@gmail.com',
        phone: '+886-7-3525138',
        fax: '+886-7-3525137',
        address: '811689高雄市楠梓區清成街72號',
        // id_code: '54958606'
      },
      Map: {
        url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7230.4322545691675!2d121.56868886119082!3d25.026738445180225!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4cdc5356571f574d!2z6byO5Ym15bel56iL6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1585287385662!5m2!1szh-TW!2stw',
      },
      UserData: {
        name: '',
        phone: '',
        email: '',
        commit: '',
      },
      meta_data: null,
    }
  },
  metaInfo() {
    return this.meta_data
  },
  components: {
    Loading: Loading,
  },
  methods: {
    SendMail() {
      var regx = /[^\u4e00-\u9fa5]/
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (this.UserData.name.length == 0) {
        alert('請輸入正確的中文姓名')
      } else if (regx.test(this.UserData.name)) {
        alert('請輸入正確的中文姓名')
      } else if (this.UserData.phone.length < 10) {
        alert('請輸入正確的電話')
      } else if (this.UserData.email.length == 0) {
        alert('請輸入正確的信箱')
      } else if (!re.test(this.UserData.email)) {
        alert('請輸入正確的信箱')
      } else if (this.UserData.commit.length == 0) {
        alert('請輸入您的問題內容')
      } else {
        document.querySelector('html,body').style.overflowY = 'hidden'
        this.Loading_open = 'Loading'
        this.axios
          .post(
            'https://www.dingchong.com.tw/php/email_handler.php',
            qs.stringify({ userdata: this.UserData })
          )
          .then((response) => {
            if (response.data == 'ok') {
              this.Loading_open = 'Finish'
              document.querySelector('html,body').style.overflowY = 'auto'
            }
          })
      }
    },
    CloseLoading() {
      this.Loading_open = 'Close'
    },
  },
  mounted() {
    window.prerenderReady = true
  },
  created() {
    this.meta_data = GetMetaData(
      '聯絡資訊',
      '',
      '/img/index-header/home_cover.jpg'
    )
  },
}
</script>
